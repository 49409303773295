@import "src/scss/module";

.StatisticHexagon {
  border: 0;
  background-color: #{$blue};
  color: #{$white};
  width: 200px;

  dl {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;

    dt {
      font-size: #{$font-size-sm};
    }

    dd {
      font-size: 50px;
      font-weight: 700;
      line-height: 1.3;
      color: #{$green};
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: 55px;
      }
    }
  }

  :global {
    .card-body {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-wrap: balance;
    }
  }
}
